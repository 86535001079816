export default defineNuxtRouteMiddleware(async (to, from) => {
  console.log('auth middleware...')

  const nuxtApp = useNuxtApp()
  const locale = nuxtApp.$i18n.locale.value

  // Don't use auth store here to prevent saving user data in the ISR cache
  const user = await useApi('/me')

  if (!user.value) {
    console.error('No user or loading user data timed out...')

    if (locale === 'de') {
      return navigateTo('/login')
    }
    return navigateTo(`/${nuxtApp.$i18n.locale.value}/login`)
  }
})
